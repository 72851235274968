<template>
    <div class="padding24" id="third_Party_order">
        <a-spin :spinning="spinning" size="large" :tip="tip">
            <div class="isDisplay" v-show="$route.meta.isShow">
                <div class="top">
                    <a-form layout="inline">
                        <a-form-item>
                            <a-button v-if="isShowAdd" icon="plus" class="margin_right20" type="primary" @click="addClick">添加</a-button>
                        </a-form-item>
                    </a-form>
                </div>
                <!-- 表格 -->
                <a-table :columns="columns" :data-source="tableData" :pagination="false" :row-key="record => record.id"  :loading="tableLoading">
                    <span slot="video_cover" slot-scope="video_cover, record">
                        <img v-viewer style="width:20px;height: 20px;" class="avatar" slot="record.video_cover" :src="record.video_cover"/>
                    </span>
                    <span slot="putaway_flag" slot-scope="putaway_flag, record">
                        <span v-if="record.putaway_flag == 0">已下架</span>
                        <span v-else-if="record.putaway_flag == 2">已上架</span>
                        <span v-else>暂不上架</span>
                    </span>
                    <span slot="operation" slot-scope="operation, record">
                        <template v-if="isShowFree">
                            <a id="copy_memberNo" v-if="record.putaway_flag == 0 || record.putaway_flag == 3" type="primary" class="margin_right10" @click="releaseClick(record)">上架</a>
                            <a-popconfirm v-if="record.putaway_flag == 2"  title="确认要下架此课程吗？" ok-text="确定" cancel-text="取消" @confirm="disableClick(record)">
                                <a id="copy_memberNo" type="primary" class="margin_right10">下架</a>
                            </a-popconfirm>
                        </template>
                        <a id="copy_memberNo" v-if="isShowEdit" class="margin_right10" type="primary" @click="editClick(record)">编辑</a>
                        <a-popconfirm title="确认要删除此课程吗？" ok-text="确定" cancel-text="取消" @confirm="deleteClick(record)">
                            <a id="copy_memberNo" v-if="isShowDelete" type="primary">删除</a>
                        </a-popconfirm>
                    </span>
                </a-table>
                <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
                <!-- 返回顶部 -->
                <a-back-top :target="targetFn" :visibilityHeight="100"/>
            </div>
        </a-spin>
        <router-view />
    </div>
</template>

<script>
import moment from "moment";
import {FreeVideoList, DeleteFreeVideo,DownFreeVideo} from "@/request/api/videoManage";
import MyPagination from "@/components/pagination/MyPagination";
import {message,popconfirm} from "ant-design-vue";
import {codeFn} from "@/utils/tools";

export default {
    components: {MyPagination},
    created() {
        this.getInitFn();
        if (codeFn("/adminv2/addFreeVideo")) this.isShowAdd = true;
        if (codeFn("/adminv2/editFreeVideo")) this.isShowEdit = true;
        if (codeFn("/adminv2/deleteFreeVideo")) this.isShowDelete = true;
        if (codeFn("/adminv2/downFreeVideo")) this.isShowFree = true;
    },
    data() {
        return {
            isShowAdd:false,
            isShowEdit:false,
            isShowDelete:false,
            isShowFree:false,

            tip: "",
            tableLoading: true,
            spinning: false, // 全局loading
            pageNo: 1,
            pageSize: 20,
            count: 0,

            columns: [
                {
                    title: "名称",
                    dataIndex: "video_name",
                    key: "video_name",
                },
                {
                    title: "封面图",
                    dataIndex: "video_cover",
                    key: "video_cover",
                    scopedSlots: {customRender: "video_cover"},
                },
                {
                    title: "所属分类",
                    dataIndex: "videoSortName",
                    key: "videoSortName",
                },
                {
                    title: "状态",
                    dataIndex: "putaway_flag",
                    key: "putaway_flag",
                    scopedSlots: {customRender: "putaway_flag"},
                },
                {
                    title: "创建时间",
                    dataIndex: "createTime",
                    key: "createTime",
                },
                {
                    title: "操作",
                    fixed: "right",
                    width: 150,
                    key: "operation",
                    dataIndex: "operation",
                    scopedSlots: {customRender: "operation"},
                },
            ],
            tableData: [],
        };
    },

    watch: {
        $route(to) {
            if (to.path === "/videoManage/freeViedo") {
                this.getInitFn();
            }
        },
    },

    methods: {
        moment,

        // 点击添加按钮
        addClick() {
            this.$router.push("/videoManage/freeViedo/addFreeViedo/0")
        },

        // 点击编辑按钮
        editClick(record) {
            this.$router.push(`/videoManage/freeViedo/addFreeViedo/${record.id}`)
        },

        // 点击上架按钮
        releaseClick(record) {
            DownFreeVideo({
                id:record.id,
                putAwayFlag:2
            }).then(({ code, data }) => {
                if(code == 200){
                    message.success('已上架')
                    this.getInitFn()
                }else{
                    message.success(data.msg)
                }
            });
        },

        // 点击下架按钮
        disableClick(record) {
            DownFreeVideo({
                id:record.id,
                putAwayFlag:0
            }).then(({ code, data }) => {
                if(code == 200){
                    message.success('已下架')
                    this.getInitFn()
                }else{
                    message.success(data.msg)
                }
            });
        },

        // 删除视频
        deleteClick(record){
            DeleteFreeVideo({
                id:record.id,
            }).then(({ code, data }) => {
                if(code == 200){
                    message.success('已删除')
                    this.getInitFn()
                }else{
                    message.success(data.msg)
                }
            });
        },

        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.getInitFn();
        },

        // 原属数据获取
        getInitFn() {
            FreeVideoList({
                pageSize:this.pageSize,
                pageNo:this.pageNo
            }).then(({ code, data }) => {
                this.tableLoading = false
                this.tableData = data.list;
                this.count = data.count
            });
        },

        // 回到顶部的函数
        targetFn() {
            return document.querySelector("#third_Party_order");
        },
    },
};
</script>

<style lang="less" scoped>
#third_Party_order {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.top {
    .top_line {
        margin-bottom: 20px;
        display: flex;

        span {
            margin-right: 20px;

            /deep/ .ant-input {
                width: 220px;
            }

            /deep/ .ant-select-selection {
                width: 110px;
                margin-left: 10px;
            }

            /deep/ .ant-calendar-picker-input {
                margin-left: 10px;
                width: auto;
            }

            /deep/ .ant-calendar-picker {
                width: 400px !important;
            }
        }

        .ant-btn {
            margin-right: 20px;
        }
    }
}
</style>
